import React from 'react';

const ErrorMessage = ({ fieldName, errors }) => {
  const error =
    typeof errors !== 'undefined' &&
    errors !== null &&
    errors.length > 0 &&
    errors.some((value) => value.field === fieldName)
      ? errors.find((value) => value.field === fieldName)
      : null;

  return error != null ? (
    <div className="block field-error mt-1 mb-1 show">{error.message}</div>
  ) : (
    <div className="block field-error hide">{fieldName}</div>
  );

  //
  // return error !== null
  //   ? <span className="field-error">{error.message}</span>
  //   : <span className="field-error"></span>;
};

export default ErrorMessage;
