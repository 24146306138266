import React from 'react';
import GoogleMapReact from 'google-map-react';
import { MapStyles } from './MapStyles';
import markerIcon from '../../assets/img/icons/logo-map-marker.png';

const ContactMap = () => {
  const defaultCenter = { lat: 48.83332443237305, lng: 2.3718345165252686 };
  const defaultZoom = 11;

  const handleGoogleApiLoaded = (map, maps) => {
    new maps.Marker({
      position: new maps.LatLng(defaultCenter.lat, defaultCenter.lng),
      map: map,
      animation: maps.Animation.DROP,
      icon: markerIcon,
    });

    // Hide controls
    map.setOptions({ disableDefaultUI: true, styles: MapStyles });
  };

  return (
    <div className="pt-10 pt-5-md">
      <p className="font-size-md text-align-center-md">
        <span className="font-size-lg">Alacaza, Station F</span>
        <br />5 Parvis Alan Turing
        <br />
        75013 PARIS
      </p>

      <div
        className="mt-5 pr-5-md pl-5-md hidden-sm"
        style={{ width: `200px`, height: `200px` }}
      >
        <GoogleMapReact
          center={defaultCenter}
          zoom={defaultZoom}
          bootstrapURLKeys={{
            key: process.env.GATSBY_GOOGLEMAPS_API_KEY,
            language: 'fr',
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            handleGoogleApiLoaded(map, maps);
          }}
        ></GoogleMapReact>
      </div>
    </div>
  );
};

export default ContactMap;
