import React, { useRef, useState } from 'react';
import { MainLayout } from '../../components/Layout';
import { navigate, withPrefix } from 'gatsby';
import BlockUi from 'react-block-ui';

import imgBg from '../../assets/img/home/bg-wave.png';

import iconSyndic from '../../assets/img/partners/syndic@2x.png';
import iconBailleur from '../../assets/img/partners/bailleur@2x.png';
import iconCollectivite from '../../assets/img/partners/collectivit@2x.png';
import iconPromoteur from '../../assets/img/partners/promoteur@2x.png';
import iconAutres from '../../assets/img/partners/partner-others.png';
import iconOperateur from '../../assets/img/contact/icon-operateur.png';
import iconSeller from '../../assets/img/contact/icon-seller.png';
import iconMan from '../../assets/img/contact/icon-man.png';
import iconWoman from '../../assets/img/contact/icon-woman.png';

import api from '../../utils/api';
import handleFormError from '../../utils/handleFormError';
import FieldError from '../../components/Error/FieldError';

import ContactMap from '../../components/Map/ContactMap';

const contactTypes = [
  {
    id: 'partner',
    title: 'Professionnel',
  },
  {
    id: 'resident',
    title: 'Particulier',
  },
];

const contactSubTypes = [
  {
    id: 'syndics',
    type: 'partner',
    icon: iconSyndic,
    title: 'Syndic de copropriété',
  },
  {
    id: 'bailleurs',
    type: 'partner',
    icon: iconBailleur,
    title: 'Bailleur social',
  },
  {
    id: 'collectivites',
    type: 'partner',
    icon: iconCollectivite,
    title: 'Collectivité territoriale',
  },
  {
    id: 'promoteurs',
    type: 'partner',
    icon: iconPromoteur,
    title: 'Promoteur immobilier',
  },
  {
    id: 'commerçants',
    type: 'partner',
    icon: iconSeller,
    title: 'Commerçant local',
  },
  {
    id: 'opérateurs',
    type: 'partner',
    icon: iconOperateur,
    title: 'Opérateur urbain',
  },
  {
    id: 'associations',
    type: 'partner',
    icon: iconWoman,
    title: 'Association',
  },
  {
    id: 'autres',
    type: 'partner',
    icon: iconAutres,
    title: 'Autres',
  },
  {
    id: 'propriétaires',
    type: 'resident',
    icon: iconWoman,
    title: 'Propriétaire',
  },
  {
    id: 'locataires',
    type: 'resident',
    icon: iconMan,
    title: 'Locataire',
  },
  {
    id: 'autres',
    type: 'resident',
    icon: iconAutres,
    title: 'Autres',
  },
];

const Contact = ({ location }) => {
  const messageMaxLength = 400;

  const formInputRef = useRef();
  const [formData, setFormData] = useState({
    type:
      location.state && location.state.contactType
        ? location.state.contactType
        : 'partner',
    subType:
      location.state && location.state.contactSubType
        ? location.state.contactSubType
        : 'syndics',
    name: '',
    email: '',
    message: '',
    isLoading: false,
    errors: [],
  });

  const setContactType = (contactType) => {
    setFormData({
      ...formData,
      type: contactType,
      subType: contactType !== formData.type ? null : formData.subType,
    });

    formInputRef.current.focus();
  };

  const setContactSubType = (contactSubType) => {
    setFormData({
      ...formData,
      subType: contactSubType,
    });

    formInputRef.current.focus();
  };

  const disableSubmit = () => {
    return formData.isLoading === true ||
      formData.type !== null ||
      formData.subType !== null ||
      formData.email !== null ||
      formData.email.trim().length > 0 ||
      formData.name !== null ||
      formData.name.trim().length > 0 ||
      formData.message !== null ||
      formData.message.trim().length > 0
      ? true
      : false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormData({ ...formData, isLoading: true });

    try {
      await api.post('/contact', {
        contactType: formData.type,
        contactSubType: formData.subType,
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });

      setFormData({
        ...formData,
        isLoading: false,
        errors: [],
      });

      navigate('/contact/merci/', {
        replace: true,
      });
    } catch (e) {
      handleFormError(e);
      setFormData({ ...formData, isLoading: false, errors: [] });
    }
  };

  return (
    <MainLayout
      siteTitle={`Contactez-nous`}
      siteDescription={`Vous souhaitez nous contacter ou rejoindre notre équipe ? Contactez-nous directement, réponse rapide garantie !`}
    >
      <BlockUi tag="div" blocking={formData.isLoading}>
        <div
          className="bg-wave"
          style={{
            minHeight: `770px`,
            backgroundImage: `url(${withPrefix(imgBg)})`,
          }}
        >
          <section className="container mt-20 mt-5-md section-contact">
            <div className="row">
              <div className="col-4 col-12-sm pl-10 pl-5-md pr-5-md">
                <div className="roboto bold font-size-xl text-align-center-md">
                  Contactez-nous
                </div>
                <ContactMap />
              </div>

              <div className="col-6 col-12-sm">
                <div className="block text-align-center-md mt-5-md">
                  {contactTypes.map((value, index) => (
                    <span
                      key={`span-${index}`}
                      className={
                        'contact-type-option ml-2 mr-2 ' +
                        (formData.type === value.id ? 'selected' : '')
                      }
                      onClick={(e) => setContactType(value.id)}
                    >
                      {value.title}
                    </span>
                  ))}
                </div>

                <div className="block contact-subtype-list mt-12">
                  <ul className="text-align-center-md">
                    {contactSubTypes
                      .filter((value) => value.type === formData.type)
                      .map((subType, index) => (
                        <li
                          key={
                            'contact-subtype-' + subType.type + '-' + subType.id
                          }
                          className={
                            'ml-2 mr-2 mb-2 ' +
                            (subType.id === formData.subType ? 'selected' : '')
                          }
                          onClick={(e) => setContactSubType(subType.id)}
                        >
                          <div className="contact-icon text-align-center pt-3 pl-2 pr-2">
                            <img src={subType.icon} alt={subType.title} />
                          </div>
                          <div className="bold roboto text-align-center pt-3 pl-2 pr-2 font-size-md font-size-sm-md text-decoration-none">
                            {subType.title}
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <p className="mt-5 pl-5-md pr-5-md">
                  Avant de commencer à écrire, dites-nous en plus sur vous en
                  sélectionnant les cases ci-dessus.{' '}
                </p>
                <div
                  className={
                    'block mt-5 pl-5-md pr-5-md row ' +
                    (formData.subType === null ? 'hide' : 'show')
                  }
                >
                  <form onSubmit={handleSubmit}>
                    <div className="col-12">
                      <div className="inline size-50 pr-1">
                        <label htmlFor="name">Nom *</label>
                        <FieldError fieldName="name" errors={formData.errors} />
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="text-input"
                          minLength={2}
                          placeholder="Nom"
                          autoComplete="name"
                          required
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                          ref={formInputRef}
                        />
                      </div>
                      <div className="inline size-50 pl-1">
                        <label htmlFor="email">Email *</label>
                        <FieldError
                          fieldName="email"
                          errors={formData.errors}
                        />
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="text-input left-icon"
                          placeholder="Email"
                          autoComplete="email"
                          required
                          ref={null}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="name">
                        Votre message *{' '}
                        <span className="textarea__count">
                          {formData.message.length}/{messageMaxLength}
                        </span>
                      </label>
                      <FieldError
                        fieldName="message"
                        errors={formData.errors}
                      />
                      <textarea
                        id="message"
                        className="text-input"
                        rows={5}
                        name="message"
                        maxLength={messageMaxLength}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                        required
                      ></textarea>
                    </div>
                    <div className="col-12 text-align-right">
                      <button
                        className={
                          'btn ' +
                          (formData.isLoading === true ? 'disabled' : '')
                        }
                        disabled={disableSubmit}
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Envoyer
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </BlockUi>
    </MainLayout>
  );
};

export default Contact;
